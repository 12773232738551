export const DEFAULT_REVALIDATE_TIME = 0;

export const BASE_BACKEND_URL =
  process.env.NEXT_PUBLIC_BASE_DYNAMIC_BACKEND_URL ||
  process.env.NEXT_PUBLIC_BASE_BACKEND_URL;
// export const BASE_BACKEND_URL = 'http://127.0.0.1:8000';
// export const BASE_BACKEND_URL = 'http://192.168.1.135:8000';

export const BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL = process.env
  .NEXT_PUBLIC_BASE_DYNAMIC_BACKEND_URL
  ? `${process.env.NEXT_PUBLIC_BASE_DYNAMIC_BACKEND_URL}/dev/s3`
  : undefined;

export const BASE_PIGELLO_SOCKET_URL = !BASE_DYNAMIC_BACKEND_PUBLIC_FILE_URL
  ? process.env.NEXT_PUBLIC_BASE_PIGELLO_SOCKET_URL
  : undefined;

export const BASE_FILEUTILS_URL = process.env.NEXT_PUBLIC_BASE_FILEUTILS_URL;

export const BASE_SERVER_URL = '';

export const BASE_ADMIN_URL = process.env.NEXT_PUBLIC_BASE_ADMIN_URL;

export const BASE_INTEGRATION_SERVICE_URL =
  process.env.NEXT_PUBLIC_BASE_INTEGRATION_SERVICE_URL;
export const BASE_INTEGRATION_MONITOR_URL =
  process.env.NEXT_PUBLIC_INTEGRATION_MONITOR_URL;

export const BASE_DASHBOARD_URL = process.env.NEXT_PUBLIC_BASE_DASHBOARD_URL;

export const COMMIT_HASH = process.env.NEXT_PUBLIC_COMMIT_HASH;

export const BASE_TF_URL = process.env.NEXT_PUBLIC_BASE_TF_URL;

export const TRACEABLE_CLIENT_TYPES = ['prod', 'dev'];

export const DEFAULT_STALE_TIME = 60 * 5 * 1000;

export const MAX_BYTES_IN_QUERY_PARAMETERS = 1980; // circa 50 ids

export const MAX_PAGE_SIZE = 500;

export const QUERY_KEYS = {
  LIST: 'list',
  COUNT: 'count',
  ALL_INSTANCES: 'allinstances',
  ALL_IDS: 'allids',
  GLOBAL_FILTER: 'globalfilter',
} as const;

export const NEVER_FETCH_NESTED_FIELDS = [
  'address',
  'tags',
  'mainAddress',
  'postalAddress',
  'invoiceAddress',
  'customerColumns',
  'previousAddress',
  'registeredAddress',
  'currentRegisteredAddress',
  'visitingAddress',
] as const;
